





































import DataProvider from "@/lib/DataProvider";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HouseholdMembersActions from "@/data/columns/HouseholdMembersActions";
import ModelTable from "@/components/ModelTable.vue";
import AddHouseholdMemberActionForm from "@/components/forms/AddHouseholdMemberActionForm.vue";
import SessionsColumns from "@/data/columns/RequestSubmissionColumns";

@Component({
  components: { ModelTable, AddHouseholdMemberActionForm },
})
export default class HouseholdMemberActionSessionSubmissionsTable extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public actionId!: string;

  private sessionsProvider: DataProvider<any> | null = null;

  private sessionsColumns = SessionsColumns;

  @Watch("actionId", { immediate: true })
  onActionIdChanged() {
    if (!this.actionId) {
      return;
    }
    this.sessionsProvider = this.$service.providers.sessionSubmissions(
      this.actionId
    );
  }
}
