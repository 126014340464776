







































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HouseholdMembersActions from "@/data/columns/HouseholdMembersActions";
import ModelTable from "@/components/ModelTable.vue";
import AddHouseholdMemberActionForm from "@/components/forms/AddHouseholdMemberActionForm.vue";
import HouseholdMemberActionSessionsTable from "@/components/HouseholdMemberActionSessionsTable.vue";
import AddHouseholdMemberSessionForm from "@/components/forms/AddHouseholdMemberSessionForm.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import EntitySelect from "@/components/EntitySelect.vue";

import QuestionnaireSubmit from "@/components/forms/QuestionnaireSubmit.vue";
@Component({
  components: {
    ModelTable,
    AddHouseholdMemberActionForm,
    HouseholdMemberActionSessionsTable,
    AddHouseholdMemberSessionForm,
    DynamicForm,
    EntitySelect,
    QuestionnaireSubmit
  }
})
export default class HouseholdMemberConsents extends Vue {
  @Prop()
  public id!: string;
  private intakeFormSubmissions: any[] | null = null;
  private member: any = null;
  addToProjectDialog = false;
  selectedProject: any = null;
  submission: any = null;
  answers: any = null;
  showResults = false;
  async mounted() {
    this.intakeFormSubmissions = (
      await this.$service.providers
        .householdMemberIntakeFormSubmissions(this.id)
        .fetchItemsAsync()
    ).items;
    this.member = await this.$service.providers.householdMembers.fetchItemAsync(
      this.id
    );
  }

  async loadSubmission(id: string) {
    const q = await this.$service.providers.questionnaireSubmissions.fetchItemAsync(
      id
    );
    this.submission = q;

    this.showResults = true;
  }
}
