var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-container',_vm._g(_vm._b({attrs:{"title":_vm.editMode ? 'Edit Service' : 'Add Service',"provider":_vm.provider,"submit":_vm.submit,"item":_vm.item},on:{"update:item":function($event){_vm.item=$event}}},'form-container',Object.assign({}, _vm.$attrs, _vm.$props),false),_vm.$listeners),[(_vm.item && _vm.projectNames.length > 0)?_c('div',{},[(!_vm.editMode)?_c('div',{staticClass:"row q-col-gutter-sm items-center"},[(_vm.userSubmissions !== null)?_c('div',{staticClass:"col-12"},[_c('q-select',{attrs:{"filled":"","display-value":_vm.projectNames.length < 2
              ? _vm.projectNames[0].label
              : _vm.projectNames.label,"options":_vm.projectNames,"option-value":function (i) { return i.value; },"option-label":function (i) { return i.label; },"label":"Select ","rules":[_vm.$rules.required]},model:{value:(_vm.projectTitle),callback:function ($$v) {_vm.projectTitle=$$v},expression:"projectTitle"}})],1):_vm._e(),_c('q-separator',{staticStyle:{"visibility":"hidden"},attrs:{"spaced":""}}),_c('div',{staticClass:"col-12"},[_c('entity-select',{ref:"select",attrs:{"isRequired":true,"readonly":!_vm.enabled,"filled":"","provider":_vm.ActionTypesProvider,"label":"Service Type","optionValue":function (i) { return (i ? i.id : null); },"optionLabel":function (i) { return (i && i.id ? ("" + (i.name)) : ''); },"rules":[_vm.$rules.required],"oDataArgs":{
            filterExpression: _vm.actionTypeFilter
          }},on:{"input":function (e) { return (_vm.selectedAction = e); }},model:{value:(_vm.ActionTypesModel),callback:function ($$v) {_vm.ActionTypesModel=$$v},expression:"ActionTypesModel"}})],1),_c('div',{staticClass:"col-12"},[(
            _vm.ActionTypesModel && _vm.ActionTypesModel.name === 'Legal Services'
          )?_c('entity-select',{ref:"select",attrs:{"isRequired":true,"readonly":!_vm.enabled,"filled":"","provider":_vm.provider,"label":"Service","value":_vm.item.actionActionTypeId,"optionValue":function (i) { return (i ? i.id : null); },"optionLabel":function (i) { return i && i.action ? ((i.action.name) + " (" + (i.actionType.name) + ")") : ''; },"rules":[_vm.$rules.required],"oDataArgs":{
            filterExpression: ("(ActionTypeId eq " + (_vm.ActionTypesModel.id) + " " + (this.filteredServices) + " and QuestionnaireId eq null)")
          }},on:{"update:value":function($event){return _vm.$set(_vm.item, "actionActionTypeId", $event)}},model:{value:(_vm.item.actionActionType),callback:function ($$v) {_vm.$set(_vm.item, "actionActionType", $$v)},expression:"item.actionActionType"}}):_vm._e(),(
            _vm.ActionTypesModel && _vm.ActionTypesModel.name !== 'Legal Services'
          )?_c('entity-select',{ref:"select",attrs:{"isRequired":true,"readonly":!_vm.enabled,"filled":"","provider":_vm.provider,"label":"Service","value":_vm.item.actionActionTypeId,"optionValue":function (i) { return (i ? i.id : null); },"optionLabel":function (i) { return i && i.action ? ((i.action.name) + " (" + (i.actionType.name) + ")") : ''; },"rules":[_vm.$rules.required],"oDataArgs":{
            filterExpression: ("(ActionTypeId eq " + (_vm.ActionTypesModel.id) + " " + (this.filteredServices) + ")")
          }},on:{"update:value":function($event){return _vm.$set(_vm.item, "actionActionTypeId", $event)}},model:{value:(_vm.item.actionActionType),callback:function ($$v) {_vm.$set(_vm.item, "actionActionType", $$v)},expression:"item.actionActionType"}}):_vm._e()],1)],1):_vm._e(),(_vm.item.project && _vm.editMode)?_c('div',{staticClass:"row q-col-gutter-sm items-center"},[_c('div',{staticClass:"col-12"},[_c('q-input',{attrs:{"filled":"","label":"Project Name","readonly":""},model:{value:(_vm.item.project.title),callback:function ($$v) {_vm.$set(_vm.item.project, "title", $$v)},expression:"item.project.title"}})],1)]):_vm._e(),_c('q-separator',{staticStyle:{"visibility":"hidden"},attrs:{"spaced":""}}),(
        _vm.item &&
          _vm.item.actionActionType &&
          _vm.item.actionActionType.action &&
          _vm.item.actionActionType.action.hasMultiples
      )?_c('div',{staticClass:"col-12 col-md-6 q-mt-md"},[_c('q-input',{attrs:{"filled":"","label":"Description","hint":" "},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}})],1):_vm._e(),_c('q-separator',{staticStyle:{"visibility":"hidden"},attrs:{"spaced":""}}),(_vm.editMode)?_c('div',{staticClass:"col-12"},[_c('entity-select',{attrs:{"isRequired":true,"filled":"","readonly":"","provider":_vm.provider,"label":"Service","value":_vm.item.actionActionTypeId,"optionValue":function (i) { return (i ? i.id : null); },"optionLabel":function (i) { return i && i.action ? ((i.action.name) + " (" + (i.actionType.name) + ")") : ''; },"rules":[_vm.$rules.required]},on:{"update:value":function($event){return _vm.$set(_vm.item, "actionActionTypeId", $event)}},model:{value:(_vm.item.actionActionType),callback:function ($$v) {_vm.$set(_vm.item, "actionActionType", $$v)},expression:"item.actionActionType"}})],1):_vm._e(),_c('div',{staticClass:"row q-col-gutter-sm items-center"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('date-picker',{attrs:{"readonly":!_vm.enabled,"label":"Start Date","rules":[_vm.$rules.required]},model:{value:(_vm.item.startDate),callback:function ($$v) {_vm.$set(_vm.item, "startDate", $$v)},expression:"item.startDate"}})],1)]),(_vm.selectedAction && _vm.selectedAction.name === 'Legal Services')?_c('div',{staticClass:"col-12 col-md-6"},[_c('q-select',{attrs:{"label":"Legal Case Status","options":['Open', 'Closed'],"filled":"","dense":""},model:{value:(_vm.item.status),callback:function ($$v) {_vm.$set(_vm.item, "status", $$v)},expression:"item.status"}})],1):_vm._e(),(
        _vm.item.actionActionType && _vm.item.actionActionType.action.assignCompanies
      )?_c('div',{staticClass:"col-12"},[_c('q-checkbox',{attrs:{"label":"Company(-ies) of HR360 Network"},model:{value:(_vm.hasHrCompanies),callback:function ($$v) {_vm.hasHrCompanies=$$v},expression:"hasHrCompanies"}}),(_vm.hasHrCompanies)?_c('entity-select',{attrs:{"filled":"","multiple":true,"provider":_vm.$service.providers.companies,"label":"Select Companies","optionLabel":function (i) { return (i && i.id ? ("" + (i.name)) : ''); }},model:{value:(_vm.item.companies),callback:function ($$v) {_vm.$set(_vm.item, "companies", $$v)},expression:"item.companies"}}):_c('q-editor',{model:{value:(_vm.item.otherCompanies),callback:function ($$v) {_vm.$set(_vm.item, "otherCompanies", $$v)},expression:"item.otherCompanies"}})],1):_vm._e(),(
        _vm.item.actionActionType &&
          !_vm.item.actionActionType.actionType.hasSessions &&
          _vm.item.actionActionType.questionnaire
      )?_c('q-separator'):_vm._e(),(
        _vm.item.actionActionType &&
          !_vm.item.actionActionType.actionType.hasSessions &&
          _vm.item.actionActionType.questionnaire
      )?_c('div',{staticClass:"row items-center"},[(_vm.item.actionActionType && _vm.item.actionActionType.questionnaire)?_c('div',{staticClass:"col-12"},[_c('dynamic-form',{attrs:{"questionnaireTitle":'Service Questionnaire',"questionnaire":_vm.item.actionActionType.questionnaire,"answers":_vm.answers},on:{"update:answers":function($event){_vm.answers=$event}}})],1):_vm._e()]):_vm._e(),(_vm.item.questionnaireSubmission && _vm.item.questionnaireSubmissionId)?_c('div',[_c('div',{staticClass:"col-12"},[(
            _vm.item.questionnaireSubmission &&
              _vm.item.questionnaireSubmission.questionnaire
          )?_c('dynamic-form',{attrs:{"questionnaireTitle":'Service Questionnaire',"submissionId":_vm.item.questionnaireSubmission
              ? _vm.item.questionnaireSubmission.id
              : null,"questionnaire":_vm.item.questionnaireSubmission.questionnaire,"answers":_vm.item.questionnaireSubmission.answers},on:{"update:answers":function($event){return _vm.$set(_vm.item.questionnaireSubmission, "answers", $event)}}}):_vm._e()],1)]):_vm._e(),_c('q-separator')],1):_vm._e(),(_vm.projectNames.length == 0)?_c('div',[_vm._v(" The beneficiary needs to participate in a project in order to get services ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }