var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('model-table',_vm._b({attrs:{"provider":_vm.sessionsProvider,"columns":_vm.sessionsColumns,"hideTop":true,"selection":'none',"sortBy":'Date',"descending":true,"allowExpand":true,"hide-bottom":"","disablePagination":true,"viewPermission":function (r) { return true; }},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}}),{key:"expand-button-content",fn:function(ref){
var scope = ref.scope;
return [_c('q-badge',{attrs:{"color":"orange","floating":"","transparent":""}},[_vm._v(" "+_vm._s(scope.row["submissions@odata.count"])+" ")]),_c('q-tooltip',[_vm._v(" View Requests ")])]}},{key:"row-actions",fn:function(ref){
var scope = ref.scope;
return [_c('q-btn',{attrs:{"disabled":_vm.disable,"icon":"edit","flat":"","no-caps":""},on:{"click":function($event){return _vm.$emit('edit', scope.row)}}},[_c('q-tooltip',[_vm._v(" Edit Session ")])],1)]}},{key:"expanded",fn:function(ref){
var scope = ref.scope;
return [_c('household-member-action-session-submissions-table',{staticStyle:{"margin-bottom":"20px"},attrs:{"actionId":scope.row.id,"viewPermission":function (r) { return true; }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('q-btn',{attrs:{"disabled":_vm.disable,"icon":"add","label":"Add Request","flat":"","no-caps":""},on:{"click":function($event){_vm.selectedItem = null;
              _vm.addRequestDialog = true;
              _vm.selectedAction = scope.row;}}})]},proxy:true},{key:"row-actions",fn:function(ref){
              var scope2 = ref.scope;
return [_c('q-btn',{attrs:{"disabled":_vm.disable,"icon":"edit","flat":"","no-caps":""},on:{"click":function($event){_vm.addRequestDialog = true;
              _vm.selectedAction = scope.row;
              _vm.selectedItem = scope2.row;}}},[_c('q-tooltip',[_vm._v(" Edit Request ")])],1)]}}],null,true)})]}}],null,true)},'model-table',Object.assign({}, _vm.$attrs, _vm.$props),false)),_c('q-dialog',{attrs:{"persistent":"","scrollable":""},model:{value:(_vm.addRequestDialog),callback:function ($$v) {_vm.addRequestDialog=$$v},expression:"addRequestDialog"}},[_c('add-household-member-session-request-form',{attrs:{"inDialog":true,"session":_vm.selectedAction ? _vm.selectedAction : null,"id":_vm.selectedItem ? _vm.selectedItem.id : null},on:{"edit":function($event){_vm.addRequestDialog = true;
        _vm.selectedAction = _vm.scope.row;},"save":function($event){_vm.addRequestDialog = false;
        _vm.onActionIdChanged();}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }