




































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import { ActionType } from "@/data/models/ActionTypes";
import EntitySelect from "@/components/EntitySelect.vue";
import DatePicker from "@/components/DatePicker.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import {
  NewQuestionnaireSubmission,
  Prescreening,
  Questionnaire,
  QuestionnaireSubmission
} from "@/data/models/Questionnaires";

@Component({
  components: {
    FormContainer,
    Loader,
    EntitySelect,
    DatePicker,
    DynamicForm
  }
})
export default class AddHouseholdMemberSessionForm extends Vue {
  @Prop()
  public householdMemberAction!: any;

  @Prop()
  public id!: string;

  public provider!: DataProvider<any>;

  public crud!: ICrudClient<any>;

  @Prop()
  public item!: any;
  private itemInternal: any = null;
  private questionnaire: any = null;

  private user: any = null;

  private answers: any[] | null = null;
  private purposes: any[] | null = null;
  async created() {
    this.provider = this.$service.providers.householdMembersActions(this.id);
    this.crud = this.$service.crud.householdMembersActions;
    this.purposes = [
      ...this.householdMemberAction.actionActionType.action.purposes,
      ...(
        await this.$service.providers.purposes.fetchItemsAsync({
          columnFilters: [
            {
              column: "ActionId",
              op: "Equals",
              value: "null"
            }
          ]
        })
      ).items
    ].sort((a, b) => (a.value < b.value ? -1 : a.value == b.value ? 0 : 1));
    //
  }
  async mounted() {
    this.onItemChanged();
  }

  @Watch("item")
  onItemChanged() {
    if (!this.item) {
      this.itemInternal = {
        householdMemberActionId: this.householdMemberAction.id,
        date:  new Date().toISOString(),
        purposeId: null
      };
      this.$emit("update:item", this.itemInternal);
    } else {
      this.itemInternal = this.item;
    }
  }
  async submit() {
    try {
      this.itemInternal.userId = (
        await this.$service.providers.users.fetchItemsAsync()
      ).items[0].id.toString();
      if (!this.itemInternal.id) {
        await this.$service.crud.sessions.addAsync([this.itemInternal]);
      } else {
        await this.$service.crud.sessions.saveAsync(this.itemInternal);
      }
    } catch (err) {
      this.$q.notify({ message: err, color: "red-4" });
      console.error(err);
      throw err;
    } finally {
      //this.submittingPrescreening = false;
    }
  }
}
