export default [
  {
    name: "session/householdMemberAction/Project/Title",
    required: true,
    label: "Project",
    align: "left",
    sortable: true, 
    field: (i: any) => i.session.householdMemberAction.project ? i.session.householdMemberAction.project.title : ''
  },
  {
    name: "session/householdMemberAction/actionActionType/actionType/Name",
    required: true,
    label: "Service",
    align: "left",
    sortable: true,
    field: (i: any) => i.session.householdMemberAction.actionActionType.actionType ?
      i.session.householdMemberAction.actionActionType.actionType.name : ""
  },
  {
    name: "name",
    required: true,
    label: "Action",
    align: "left",
    sortable: true,
    field: (i: any) => i.name
  },
  {
    name: "request/value",
    required: true,
    label: "Request",
    align: "left",
    sortable: true,
    field: (i: any) => i.request.value
  },
  {
    name: "requestComment",
    required: true,
    label: "Request Comment",
    align: "left",
    sortable: true,
    field: (i: any) => i.requestComment
  },
  {
    name: "nextStep/value",
    required: true,
    label: "Next Step",
    align: "left",
    sortable: true,
    field: (i: any) => i.nextStep && i.nextStep.value? i.nextStep.value : ''
  },
  {
    name: "nextStepComment",
    required: true,
    label: "Next Step Comment",
    align: "left",
    sortable: true,
    field: (i: any) => i.nextStepComment
  },
  {
    name: "status",
    required: true,
    label: "Status",
    align: "left",
    sortable: true,
    field: (i: any) => i.status
  }
];
