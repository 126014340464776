






























































































import DataProvider from "@/lib/DataProvider";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HouseholdMembersActions from "@/data/columns/HouseholdMembersActions";
import ModelTable from "@/components/ModelTable.vue";
import AddHouseholdMemberActionForm from "@/components/forms/AddHouseholdMemberActionForm.vue";
import SessionsColumns from "@/data/columns/Sessions";
import HouseholdMemberActionSessionSubmissionsTable from "@/components/HouseholdMemberActionSessionSubmissionsTable.vue";
import AddHouseholdMemberSessionRequestForm from "@/components/forms/AddHouseholdMemberSessionRequestForm.vue";
@Component({
  components: {
    ModelTable,
    AddHouseholdMemberActionForm,
    HouseholdMemberActionSessionSubmissionsTable,
    AddHouseholdMemberSessionRequestForm
  }
})
export default class HouseholdMemberActionSessionsTable extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public actionId!: string;

  @Prop()
  public disable!: boolean;

  private sessionsProvider: DataProvider<any> | null = null;
  private selectedItem: any = null;
  private sessionsColumns = SessionsColumns;
  private addRequestDialog = false;
  private selectedAction: any = null;

  @Watch("actionId", { immediate: true })
  onActionIdChanged() {
    if (!this.actionId) {
      return;
    }
    this.sessionsProvider = this.$service.providers.householdMembersActionSessions(
      this.actionId
    );
  }
}
