import { render, staticRenderFns } from "./AddHouseholdMemberActionForm.vue?vue&type=template&id=313e973e&"
import script from "./AddHouseholdMemberActionForm.vue?vue&type=script&lang=ts&"
export * from "./AddHouseholdMemberActionForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QSeparator,QInput,QCheckbox,QEditor});
