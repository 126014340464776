






































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import DatePicker from "@/components/DatePicker.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import { toArray, toMap } from "@/lib/answers";
import { NewQuestionnaireSubmission } from "@/data/models/Questionnaires";

@Component({
  components: {
    FormContainer,
    Loader,
    EntitySelect,
    DatePicker,
    DynamicForm
  }
})
export default class AddHouseholdMemberSessionRequestForm extends Vue {
  @Prop()
  public session!: any;

  public provider!: DataProvider<any>;

  public crud!: ICrudClient<any>;

  @Prop()
  public id!: string;

  private itemInternal: any = null;

  private questionnaire: any = null;

  private user: any = null;

  private answers: any[] | null = null;
  private purposes: any[] | null = null;
  private requestAnswers: any[] = [];
  private nextStepAnswers: any[] = [];
  private nextStepQuestionnaire: any | null = null;
  private requestQuestionnaire: any | null = null;
  async mounted() {
    this.onItemChanged();
  }

  @Watch("itemInternal", { deep: true })
  async onItemChanged() {
    if (!this.itemInternal) {
      this.itemInternal = {
        name: "",
        sessionId: this.session.id,
        requestId: null,
        request: null,
        requestComment: null,
        requestQuestionnaireSubmission: null,
        nextStepId: null,
        nextStep: null,
        nextStepComment: null,
        nextStepQuestionnaireSubmission: null,
        status: "Pending"
      };
      this.$emit("update:item", this.itemInternal);
    } else {
      if (this.itemInternal.requestQuestionnaireSubmission) {
        this.requestAnswers = this.itemInternal.requestQuestionnaireSubmission.answers;
      }
      if (this.itemInternal.nextStepQuestionnaireSubmission) {
        this.nextStepAnswers = this.itemInternal.nextStepQuestionnaireSubmission.answers;
      }
      if (
        !this.nextStepQuestionnaire ||
        this.itemInternal?.nextStep?.questionnaireId !==
          this.nextStepQuestionnaire.id
      ) {
        this.nextStepQuestionnaire =
          this.itemInternal.nextStep &&
          this.itemInternal.nextStep.questionnaireId
            ? await this.$service.providers.questionnaires.fetchItemAsync(
                this.itemInternal.nextStep.questionnaireId
              )
            : null;
      }
      if (
        !this.requestQuestionnaire ||
        this.itemInternal?.request?.questionnaireId !==
          this.requestQuestionnaire.id
      ) {
        this.requestQuestionnaire =
          this.itemInternal.request && this.itemInternal.request.questionnaireId
            ? await this.$service.providers.questionnaires.fetchItemAsync(
                this.itemInternal.request.questionnaireId
              )
            : null;
      }
    }
  }

  async submit() {
    try {
      if (this.itemInternal.request.questionnaireId) {
        this.itemInternal.requestQuestionnaireSubmission = NewQuestionnaireSubmission(
          this.itemInternal.request.questionnaireId,
          this.requestAnswers
        );
      } else {
        this.itemInternal.requestQuestionnaireSubmission = null;
      }
      if (
        this.itemInternal.nextStep &&
        this.itemInternal.nextStep.questionnaireId
      ) {
        this.itemInternal.nextStepQuestionnaireSubmission = NewQuestionnaireSubmission(
          this.itemInternal.nextStep.questionnaireId,
          this.nextStepAnswers
        );
      } else {
        this.itemInternal.nextStepQuestionnaireSubmission = null;
      }
      if (!this.itemInternal || !this.itemInternal.id) {
        await this.$service.crud.sessionSubmissions.addAsync([
          this.itemInternal
        ]);
      } else {
        await this.$service.crud.sessionSubmissions.saveAsync(
          this.itemInternal
        );
      }
    } catch (err) {
      this.$q.notify({ message: err, color: "red-4" });
      console.error(err);
      throw err;
    } finally {
      this.$router.go(-1);
      //this.submittingPrescreening = false;
    }
  }
}
