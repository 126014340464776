
























import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import { PrescreeningActionTaken } from "@/data/models/PrescreeningActionTaken";
import { Project } from "@/data/models/Projects";
import {
  NewQuestionnaireSubmission,
  Prescreening,
  Questionnaire,
  QuestionnaireSubmission,
} from "@/data/models/Questionnaires";
import { toMap, toArray } from "@/lib/answers";
import { User } from "@/data/models/Users";
import { Guid } from "guid-typescript";
@Component({
  components: {
    FormContainer,
    Loader,
    DynamicForm,
  },
})
export default class QuestionnaireSubmit extends Vue {
  @Prop()
  public questionnaireId!: string;

  @Prop()
  public beneficiaryId!: string;

  @Prop()
  public routeAfterSave!: (item: any) => Location;

  @Prop()
  public routeAfterSaveAndClose!: (item: any) => Location;

  private provider!: DataProvider<QuestionnaireSubmission>;
  private crud!: ICrudClient<QuestionnaireSubmission>;
  @Prop()
  public prescreeningActionTakenProvider!: DataProvider<PrescreeningActionTaken>;

  @Prop()
  public usersProvider!: DataProvider<User>;

  @Prop()
  public projectsProvider!: DataProvider<Project>;

  @Prop()
  public inDialog!: boolean;
  private item: any = null;
  private submission: any = null;
  private prescreeningSubmission: any = null;
  private assignUser: any = {};
  private user: any = {};
  private submittingPrescreening = false;
  private blocklist = ["1234", "2345"];
  private found = false;
  private notFound = false;
  private assignTo = false;
  private intake = false;
  private consentFile: any = null;
  private users: any[] = [];
  private assignToUsers: any[] = [];
  private projects: any[] = [];
  private saving = false;
  private consent: any = {
    informational: false,
    promotional: false,
    share: false,
  };
  private prescreeningId: any = "";

  created() {
    this.provider = this.$service.providers.questionnaireSubmissions;
    this.crud = this.$service.crud.questionnaireSubmissions;
  }

  async initialize() {
    if (!this.beneficiaryId && this.questionnaireId) {
      this.submission = await this.provider.fetchItemAsync(
        this.questionnaireId
      );
      this.users = (
        await this.$service.providers.users.fetchItemsAsync()
      ).items;
    } else if (this.questionnaireId) {
      const q = await this.$service.providers.questionnaires.fetchItemAsync(
        this.questionnaireId
      );

      this.submission = {
        questionnaire: q,
        questionnaireId: q.id,
        beneficiaryId: this.beneficiaryId,
        answers: [],
      };
    }
  }

  async submit() {
    return await this.submitPrescreening();

    //debugger;
  }

  createUniqueId() {
    let result = "";
    const length = 8;
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  async submitPrescreening() {
    try {
      this.submittingPrescreening = true;
      this.submission.answers = toArray(this.submission.answers);
      if (this.submission.id) {
        await this.crud.saveAsync(this.submission);
      } else {
        const res = await this.crud.addAsync([this.submission]);
        this.submission = res[0];
      }
      this.$emit("save");
    } finally {
      this.submittingPrescreening = false;
    }
    return this.submission;
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
