















































































































































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import { ActionType } from "@/data/models/ActionTypes";
import EntitySelect from "@/components/EntitySelect.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import DatePicker from "@/components/DatePicker.vue";
import {
  NewQuestionnaireSubmission,
  Prescreening,
  Questionnaire,
  QuestionnaireSubmission
} from "@/data/models/Questionnaires";
import ItemsResult from "@/lib/ItemsResult";

@Component({
  components: {
    FormContainer,
    Loader,
    EntitySelect,
    DatePicker,
    DynamicForm
  }
})
export default class AddHouseholdMemberActionForm extends Vue {
  @Prop()
  public memberId!: string;
  @Prop()
  public editMode!: boolean;
  @Prop()
  public action!: string;

  public provider!: DataProvider<any>;
  public ActionTypesProvider!: DataProvider<ActionType>;

  public householdMemberAction!: ItemsResult<ActionType>;
  private filteredServices: any = null;
  private userSubmissions: any = null;
  private projectNames: any[] = [];
  private projectTitle: any = [];
  private item: any = null;
  private questionnaire: any = null;
  private answers: any[] = [];
  private enabled = false;
  private ActionTypesModel: any = null;
  private householdActions: any = [];
  private selectedAction: any = null;
  private hasHrCompanies = false;
  private currentUser: any;
  private actionTypeFilter = "";

  async created() {
    this.provider = this.$service.providers.actionActionTypes;
    this.ActionTypesProvider = this.$service.providers.actionTypes;
    this.userSubmissions = await this.$service.providers
      .householdMemberIntakeFormSubmissions(this.memberId)
      .fetchItemsAsync();

    this.loadQuestionnaire();

    this.currentUser = await this.$service.providers.users.fetchItemAsync(
      this.$auth.userInfo.profile.sub
    );
    if (!this.$auth.permissions.admin.is) {
      if (!this.currentUser.isLawyer) {
        this.actionTypeFilter = "IsLegal ne true";
      }
      if (!this.currentUser.isPsychologist) {
        this.actionTypeFilter +=
          this.actionTypeFilter !== ""
            ? " and IsPsychological ne true"
            : "IsPsychological ne true";
      }
    }

    this.projectNames = this.userSubmissions.items.filter((i: any) => {
      return !i.endDate;
    });

    this.projectNames = this.projectNames.map((i: any) => {
      return { label: i.project.title, value: i.project.id };
    });

    this.householdMemberAction = await this.$service.providers
      .householdMembersActions(this.memberId)
      .fetchItemsAsync();

    this.householdActions = this.householdMemberAction.items;

    this.filterActions();
  }

  setCompanies() {
    if (this.hasHrCompanies) {
      this.item.otherCompanies = "";
    } else {
      this.item.companies = null;
    }
  }

  @Watch("projectNames")
  onProjectNameChange() {
    if (this.projectNames.length < 2) {
      this.projectTitle = this.projectNames[0];
    }
  }

  @Watch("projectTitle")
  onProjectTitleChange() {
    this.item.projectId = this.projectTitle.value;
    this.ActionTypesModel = null;
    this.filterActions();
  }

  @Watch("ActionTypesModel", { immediate: true })
  onActionTypesModelChanged() {
    if (this.$refs.select) {
      this.$nextTick(() => (this.$refs.select as any).loadOptions());
    }
  }

  @Watch("action", { immediate: true })
  onActionChanged() {
    if (!this.action) {
      this.enabled = true;
      this.item = {
        householdMemberId: this.memberId,
        actionActionTypeId: null,
        startDate: new Date().toISOString(),
        projectId: null,
        description: null,
        status: "Open",
        otherCompanies: "",
        companies: null
      };
    } else {
      this.enabled = false;
      this.item = this.action;
      if (this.item.actionActionType.actionType.name === "Legal Services") {
        this.selectedAction = this.item.actionActionType.actionType;
      }
    }
    if (this.item.companies && this.item.companies.length > 0) {
      this.hasHrCompanies = true;
    }
  }

  filterActions() {
    if (this.householdActions) {
      const houseHoldActions = this.householdActions
        .filter((act: any) => act.projectId === this.item.projectId)
        .map((x: any) => {
          if (x.actionActionType !== null && x.actionActionType.action) {
            return x.actionActionType.action;
          }
        });
      this.filteredServices = houseHoldActions
        .map((x: any) => {
          if (!x.hasMultiples) {
            // if ()
            return `and Action/Name ne '${encodeURIComponent(x.name)}'`;
          }
        })
        .join(" ")
        .trim();
    }
  }

  async submit() {
    try {
      this.item.endDate = new Date().toISOString().split("T")[0];
      this.setCompanies();
      if (!this.item.id) {
        //this.submittingPrescreening = true;
        let questionnaireSubmission = null;
        if (this.item.actionActionType.questionnaire) {
          questionnaireSubmission = NewQuestionnaireSubmission(
            this.item.actionActionType.questionnaire.id,
            this.answers || []
          );
        }
        if (this.projectNames.length < 2) {
          this.item.projectId = this.projectNames[0].value;
        }
        const i = {
          questionnaireSubmission: questionnaireSubmission,
          householdMemberId: this.item.householdMemberId,
          actionActionTypeId: this.item.actionActionTypeId,
          startDate: this.item.startDate,
          projectId: this.item.projectId,
          description: this.item.description,
          companies: this.item.companies,
          otherCompanies: this.item.otherCompanies
        };

        await this.$service.crud.householdMembersActions.addAsync([i]);
      } else {
        await this.$service.crud.householdMembersActions.saveAsync(this.item);
      }
    } catch (err) {
      this.$q.notify({ message: err, color: "red-4" });
      console.error(err);
      throw err;
    } finally {
      //this.submittingPrescreening = false;
    }
  }

  async loadQuestionnaire() {
    if (this.item.questionnaireSubmissionId) {
      const questionnaire = await this.$service.providers.questionnaireSubmissions.fetchItemAsync(
        this.item.questionnaireSubmissionId
      );
      this.item.questionnaireSubmission = questionnaire;
    }
    this.$forceUpdate();
  }
}
